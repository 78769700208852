import { Column, Picture, Text } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const Body = () => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  return (
    <Column
      alignItems='center'
      customHeight={isMobile ? 'calc(100% - 100px)' : '100%'}
      id={getBemId('modalSaveCard', 'container')}
      justifyContent='center'
    >
      <Column alignItems='center'>
        <Picture
          alt='grapes'
          height='180px'
          src='/images/grapes.png'
          width='114.5px'
        />
      </Column>
      <Column alignItems='center'>
        <Column
          alignItems='center'
          padding='4px 12px'
        >
          <Text
            fontSize='xl'
            fontWeight='semibold'
          >
            Guardar tarjeta
          </Text>
        </Column>
        <Column
          maxWidth='332px'
          padding='8px 0px'
          width='100%'
        >
          <Text
            fontSize='base'
            textAlign='center'
          >
            ¿Quieres guardar los datos de tu tarjeta para tus futuras compras?
          </Text>
        </Column>
      </Column>
    </Column>
  )
}
