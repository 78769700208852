import { Button, Column } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface IFooterProps {
  handleSave: () => void
  handleDelete: () => void
  isLoading: boolean
}

export const Footer = ({
  handleDelete,
  handleSave,
  isLoading
}: IFooterProps) => {
  return (
    <Column gap={16}>
      <Button
        borderRadius={getGlobalStyle('--border-radius-xl')}
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId('modalSaveCard', 'saveCard')}
        isLoading={isLoading}
        label='Sí, guardar mi tarjeta'
        onClick={handleSave}
        padding='8px 16px'
      />
      <Button
        borderRadius={getGlobalStyle('--border-radius-xl')}
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId('modalSaveCard', 'deleteCard')}
        label='Eliminar después de pagar'
        onClick={handleDelete}
        padding='8px 16px'
        type='secondary-gray'
      />
    </Column>
  )
}
