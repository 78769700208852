import { isProd } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

export const LAYOUT_WIDTH = '1120px'
// https://images.ctfassets.net/un6yvtd6uq5z/4VBYcGoU7RUIkU00tJFkBy/d09123da1bf2d273858427d165dda6fd/delete-card-pear-modal.jpg
export const MODAL_IMAGES = {
  APPLE: '5jBbmMvgRuLWBuSsLptl16'
}

export const EMOJIS = {
  UMBRELLA: '⛱️',
  HOUSE: '🏡',
  FOLDER: '💼',
  HEARTS: '💕',
  STAR_EYES: '🤩'
}

export const PAYMENT_METHODS = {
  GIFTCARD: 'Giftcard',
  CREDITCARD: 'credit',
  DEBITCARD: 'debit',
  PREPAGO: 'prepago'
}

export const PAYMENT_METHODS_SPANISH = {
  GIFTCARD: 'Giftcard',
  CREDITCARD: 'Crédito',
  DEBITCARD: 'Débito',
  PREPAGO: 'Prepago'
}

export const PAYMENT_PROVIDERS = {
  KLAP: 'KLAP',
  TRANSBANK: 'TRANSBANK',
  GEOCOM: 'GEOCOM',
  UNICARD: 'UNICARD',
  BANCOESTADO: 'TRANSBANK',
  UNIPAY: 'UNIPAY'
}

export const PAYMENT_CARD_TYPES = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  REDCOMPRA: 'redcompra',
  GIFTCARD: 'giftcard',
  AMERICANEXPRESS: 'americanexpress',
  PREPAGO: 'prepago'
}

export const CARD_TYPE_CATEGORY = {
  [PAYMENT_CARD_TYPES.VISA]: 'credit',
  [PAYMENT_CARD_TYPES.MASTERCARD]: 'credit',
  [PAYMENT_CARD_TYPES.REDCOMPRA]: 'debit',
  [PAYMENT_CARD_TYPES.GIFTCARD]: 'debit', // Example categorization
  [PAYMENT_CARD_TYPES.AMERICANEXPRESS]: 'credit',
  [PAYMENT_CARD_TYPES.PREPAGO]: 'debit'
}

export const PAYMENT_UNIPAY_FORMAT = 'unipay'
export const PAYMENT_BANCOESTADO_FORMAT = 'banco-estado'
export const PAYMENT_ONECLICK_FORMAT = 'oneclick'
export const PAYMENT_KLAP_FORMAT = 'klap'

export const PAYMENT_PROVIDERS_DICTIONARY = [
  PAYMENT_UNIPAY_FORMAT,
  PAYMENT_BANCOESTADO_FORMAT,
  PAYMENT_ONECLICK_FORMAT,
  PAYMENT_KLAP_FORMAT
]

// https://it-smu.atlassian.net/wiki/spaces/MDP/pages/702939150/C+digo+interno+medios+de+pagos
export const CODE_ONECLICK_DEBIT = '11' // Oneclick Débito
export const CODE_ONECLICK_CREDIT = '12' // Oneclick Crédito
export const CODE_KLAP_DEBIT = '13' // Klap Débito
export const CODE_KLAP_CREDIT = '14' // Klap Crédito
export const CODE_UNIPAY_WITH_INSTALLMENTS = '16' // Unipay con cuotas
export const CODE_UNIPAY_WITHOUT_INSTALLMENTS = '15' // Unipay sin cuotas
export const CODE_GIFTCARD = '17' //Giftcard
export const CODE_BANCOESTADO_DEBIT = '19' //Banco Estado Débito
export const CODE_BANCOESTADO_CREDIT = '20' //Banco Estado Crédito

export const PAYMENT_PROVIDERS_DICTIONARY_CODE = {
  [CODE_ONECLICK_DEBIT]: PAYMENT_PROVIDERS.TRANSBANK, // Oneclick Débito
  [CODE_ONECLICK_CREDIT]: PAYMENT_PROVIDERS.TRANSBANK, // Oneclick Crédito
  [CODE_KLAP_DEBIT]: PAYMENT_PROVIDERS.KLAP, // Klap Débito
  [CODE_KLAP_CREDIT]: PAYMENT_PROVIDERS.KLAP, // Klap Crédito
  [CODE_UNIPAY_WITH_INSTALLMENTS]: PAYMENT_PROVIDERS.UNIPAY, // Unipay con cuotas
  [CODE_UNIPAY_WITHOUT_INSTALLMENTS]: PAYMENT_PROVIDERS.UNIPAY, // Unipay sin cuotas
  [CODE_GIFTCARD]: PAYMENT_PROVIDERS.GEOCOM, //Giftcard
  [CODE_BANCOESTADO_DEBIT]: PAYMENT_PROVIDERS.BANCOESTADO, //Banco Estado Débito
  [CODE_BANCOESTADO_CREDIT]: PAYMENT_PROVIDERS.BANCOESTADO //Banco Estado Crédito
}

export const PAYMENT_IMAGES = {
  [CODE_UNIPAY_WITH_INSTALLMENTS]:
    '/images/cards_images/unipay_card_minified.png',
  [CODE_UNIPAY_WITHOUT_INSTALLMENTS]:
    '/images/cards_images/unipay_card_minified.png',
  [CODE_BANCOESTADO_DEBIT]: '/images/cards_images/banco_estado_card.png',
  [CODE_BANCOESTADO_CREDIT]: '/images/cards_images/banco_estado_card.png',
  BANCOESTADONEW: '/images/cards_images/banco_estado_new_card.png',
  [PAYMENT_BANCOESTADO_FORMAT]: '/images/cards_images/banco_estado_card.png',
  [CODE_ONECLICK_CREDIT]: '/images/cards_images/oneclick_credit_card.png',
  [CODE_ONECLICK_DEBIT]: '/images/cards_images/oneclick_debit_card.png',
  [PAYMENT_CARD_TYPES.PREPAGO]: '/images/cards_images/prepago_card.png',
  [PAYMENT_ONECLICK_FORMAT]: '/images/cards_images/oneclick_debit_card.png',
  [CODE_KLAP_CREDIT]: '/images/cards_images/klap_credit_card.png',
  [CODE_KLAP_DEBIT]: '/images/cards_images/klap_debit_card.png',
  [PAYMENT_CARD_TYPES.REDCOMPRA]: '/images/cards_images/red_compra_card.png',
  [PAYMENT_CARD_TYPES.MASTERCARD]: '/images/cards_images/master-card_card.png',
  [PAYMENT_CARD_TYPES.VISA]: '/images/cards_images/visa_card.png',
  [PAYMENT_CARD_TYPES.AMERICANEXPRESS]:
    '/images/cards_images/american_express_card.png'
}

export const CARD_TYPE_BY_ID_SPANISH = {
  [CODE_ONECLICK_DEBIT]: PAYMENT_METHODS_SPANISH.DEBITCARD,
  [CODE_ONECLICK_CREDIT]: PAYMENT_METHODS_SPANISH.CREDITCARD,
  [CODE_KLAP_DEBIT]: PAYMENT_METHODS_SPANISH.DEBITCARD,
  [CODE_KLAP_CREDIT]: PAYMENT_METHODS_SPANISH.CREDITCARD,
  [CODE_UNIPAY_WITH_INSTALLMENTS]: PAYMENT_METHODS_SPANISH.CREDITCARD,
  [CODE_UNIPAY_WITHOUT_INSTALLMENTS]: PAYMENT_METHODS_SPANISH.CREDITCARD,
  [CODE_GIFTCARD]: PAYMENT_METHODS_SPANISH.GIFTCARD,
  [CODE_BANCOESTADO_DEBIT]: PAYMENT_METHODS_SPANISH.DEBITCARD,
  [CODE_BANCOESTADO_CREDIT]: PAYMENT_METHODS_SPANISH.CREDITCARD
}
// this order is used to show the payment methods in the modal list
export const PAYMENT_METHODS_ORDER_TO_SHOW = [
  {
    codes: [CODE_UNIPAY_WITH_INSTALLMENTS, CODE_UNIPAY_WITHOUT_INSTALLMENTS],
    formatProvider: {
      [CODE_UNIPAY_WITH_INSTALLMENTS]: PAYMENT_UNIPAY_FORMAT,
      [CODE_UNIPAY_WITHOUT_INSTALLMENTS]: PAYMENT_UNIPAY_FORMAT
    },
    title: 'Unipay',
    subtitle: 'Descuento exclusivo',
    image: PAYMENT_IMAGES[CODE_UNIPAY_WITH_INSTALLMENTS]
  },
  {
    codes: [CODE_BANCOESTADO_DEBIT, CODE_BANCOESTADO_CREDIT],
    formatProvider: {
      [CODE_BANCOESTADO_DEBIT]: PAYMENT_BANCOESTADO_FORMAT,
      [CODE_BANCOESTADO_CREDIT]: PAYMENT_BANCOESTADO_FORMAT
    },
    title: 'Banco Estado',
    subtitle: 'Descuento exclusivo',
    image: PAYMENT_IMAGES.BANCOESTADONEW
  },
  {
    codes: [CODE_ONECLICK_DEBIT],
    formatProvider: {
      [CODE_ONECLICK_DEBIT]: PAYMENT_ONECLICK_FORMAT
    },
    title: 'Débito/Prepago',
    subtitle: 'Tu tarjeta se guardará',
    image: PAYMENT_IMAGES[CODE_ONECLICK_DEBIT]
  },
  {
    codes: [CODE_ONECLICK_CREDIT],
    formatProvider: {
      [CODE_ONECLICK_CREDIT]: PAYMENT_ONECLICK_FORMAT
    },
    title: 'Crédito',
    subtitle: 'Tu tarjeta se guardará',
    image: PAYMENT_IMAGES[CODE_ONECLICK_CREDIT]
  },
  {
    codes: [CODE_KLAP_DEBIT],
    formatProvider: {
      [CODE_KLAP_DEBIT]: PAYMENT_KLAP_FORMAT
    },
    title: 'Débito/Prepago',
    subtitle: 'Tu tarjeta se guardará',
    image: PAYMENT_IMAGES[CODE_KLAP_DEBIT]
  },
  {
    codes: [CODE_KLAP_CREDIT],
    formatProvider: {
      [CODE_KLAP_CREDIT]: PAYMENT_KLAP_FORMAT
    },
    title: 'Crédito',
    subtitle: 'Tu tarjeta se guardará',
    image: PAYMENT_IMAGES[CODE_KLAP_CREDIT]
  }
]

export const TOAST_MESSAGES = {
  success_deleted: '¡Tu tarjeta ha sido eliminada!',
  error_deleted: 'No pudimos eliminar tu tarjeta, vuelve a intentarlo.',
  success_created: '¡Tu tarjeta ha sido creada!',
  error_created:
    'No pudimos agregar tu nuevo medio de pago, Intenta nuevamente.',
  error_transaction:
    'Tuvimos un problema al procesar tu pago, intenta nuevamente.',
  error_setcard:
    'No pudimos cambiar tu nuevo medio de pago. Intenta nuevamente.',
  error_not_found_coupon: 'Tu código de descuento no es válido.',
  error_expired_coupon: 'Tu código ha expirado.',
  error_general_coupon:
    'No pudimos  guardar tu código de descuento. Intenta nuevamente',
  error_already_used_coupon: 'Tu código ya fue utilizado.',
  success_added_coupon: '¡Código aplicado con éxito!',
  info_terms_and_conditions:
    'Para pagar acepta  los términos y condiciones y políticas de privacidad.'
}

export const CONTENTFUL_IDS = {
  TERMS_AND_CONDITIONS: '5wMOZMajUIabGwlP8jSHAc',
  PRIVACY_POLICY: '51ZjR0C46Vln1ZuGqKVYJL',
  CHECKOUT_CONFIG: '6Ga7kt8efFpybsEp3CZmeS'
}

export const CONTENTFUL_TYPES = {
  GENERAL_CONFIG: 'configuracionesGenerales'
}

export const CONTENTFUL_IMG_IDS = {
  REMOVE_GC_MODAL_IMG: '2ejQ9Hxl2LUibU5QJkPvWr',
  ERROR_404_IMG: '1mCxqNwobrC0dofjXgB8iW'
}

export const DEFAULT_NUMBER_OF_INSTALLMENTS = [
  { id: '0', text: 'Sin cuotas' },
  { id: '3', text: '3 cuotas' },
  { id: '6', text: '9 cuotas' },
  { id: '9', text: '6 cuotas' },
  { id: '12', text: '12 cuotas' }
]

// checkout exceptions
export const CHECKOUT_WITHOUT_COUPONS = 'EXC-01'
export const CHECKOUT_WITHOUT_PROFILE = 'EXC-02'
export const CHECKOUT_WITHOUT_ADDRESS = 'EXC-03'
export const CHECKOUT_WITHOUT_SELECTED_PAYMENT = 'EXC-04'
export const CHECKOUT_WITHOUT_SELECTED_WINDOW = 'EXC-05'
export const CHECKOUT_WITHOUT_PRODUCTS = 'EXC-06'

export const CHECKOUT_EXCEPTIONS = [
  CHECKOUT_WITHOUT_COUPONS,
  CHECKOUT_WITHOUT_PROFILE,
  CHECKOUT_WITHOUT_ADDRESS,
  CHECKOUT_WITHOUT_SELECTED_PAYMENT,
  CHECKOUT_WITHOUT_SELECTED_WINDOW,
  CHECKOUT_WITHOUT_PRODUCTS
]

export const TOAST_TYPES = {
  success: 'success',
  error: 'error',
  info: 'info'
}

export const TOAST_MESSAGES_MODAL_UNIPAY_OTP = {
  info: 'El código de verificación ha sido enviado.\n Revisa tus mensajes.',
  success: '¡Ya puedes pagar con tu tarjeta Unipay!',
  error: 'No se pudo enviar el código de verificación. Inténtalo de nuevo.'
}

export const ERROR_MESSAGE_UNIPAY_1 =
  'No encontramos información asociada a tu RUT.'
export const ERROR_MESSAGE_UNIPAY_2 =
  'Hubo un problema. Ingresa tus datos nuevamente.'
export const ERROR_MESSAGE_UNIPAY_3 =
  'Tarjeta bloqueada. Contacta a Unipay o prueba otro medio de pago.'
export const ERROR_MESSAGE_UNIPAY_4 =
  'Hubo un problema. Intenta nuevamente o prueba con otro medio de pago.'
export const ERROR_MESSAGE_UNIPAY_5 =
  'El código de verificación es incorrecto. Inténtalo nuevamente.'
export const ERROR_MESSAGE_UNIPAY_6 =
  'Hubo un problema. Ingresa nuevamente tu código.'
export const ERROR_MESSAGE_UNIPAY_INSTALLMENTS =
  'Tuvimos un problema al agregar las cuotas. Intenta otra vez.'

// Unipay exceptions
// https://it-smu.atlassian.net/wiki/spaces/MDP/pages/626688015/Errores+Unipay
export const UNIPAY_EXCEPTIONS = [
  {
    code_errors: ['CHK-UNI-10', 'CHK-UNI-24'],
    message: ERROR_MESSAGE_UNIPAY_1
  },
  {
    code_errors: [
      'CHK-UNI-04',
      'CHK-UNI-08',
      'CHK-UNI-10',
      'CHK-UNI-17',
      'CHK-UNI-19'
    ],
    message: ERROR_MESSAGE_UNIPAY_2
  },
  {
    code_errors: [
      'CHK-UNI-09',
      'CHK-UNI-11',
      'CHK-UNI-12',
      'CHK-UNI-13',
      'CHK-UNI-14',
      'CHK-UNI-15',
      'CHK-UNI-16',
      'CHK-UNI-18',
      'CHK-UNI-20',
      'CHK-UNI-21',
      'CHK-UNI-22',
      'CHK-UNI-23'
    ],
    message: ERROR_MESSAGE_UNIPAY_3
  },
  {
    code_errors: ['CHK-UNI-06'],
    message: ERROR_MESSAGE_UNIPAY_5
  },
  {
    code_errors: ['CHK-UNI-07', 'CHK-UNI-08'],
    message: ERROR_MESSAGE_UNIPAY_6
  }
]

export const KIND_OF_GIFTCARD = {
  NOMINATIVA: 'NOMINATIVE',
  GIFTCARD: 'GIFTCARD'
}

export const GIFTCARD_IMAGES = {
  [KIND_OF_GIFTCARD.NOMINATIVA]: [
    '/images/giftcards/gc_nominativa1.png',
    '/images/giftcards/gc_nominativa2.png',
    '/images/giftcards/gc_nominativa3.png',
    '/images/giftcards/gc_nominativa4.png'
  ],
  [KIND_OF_GIFTCARD.GIFTCARD]: [
    '/images/giftcards/gc_normal1.png',
    '/images/giftcards/gc_normal2.png',
    '/images/giftcards/gc_normal3.png',
    '/images/giftcards/gc_normal4.png'
  ]
}

export const ANIMATIONS_FILES_TRANSITION = {
  TRANSACTION_ANIMATION: {
    NAME: 'transactionAnimation.json',
    ID: isProd ? '3gD8RFdNgsPtEmAQjPDPMG' : '4tHPLJ0eEIq6CN9Sbl1lSz',
    SECOND_ID: isProd
      ? '5c62ec5efde0f782fa96e85f1400a9df'
      : '111ad526e655b21dc4bfe3a927b6fb4c'
  }
}

export const LOCALSTORAGE_KEYS = {
  SHOW_GC_NOMINATIVE_TOAST: 'showGcNominativeToast'
}

export const SAVE_CARDS_OPTIONS_ALLOWED = [
  CODE_ONECLICK_CREDIT,
  CODE_ONECLICK_DEBIT,
  CODE_BANCOESTADO_DEBIT,
  CODE_BANCOESTADO_CREDIT
]
