import { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  Column,
  Icon,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { trigger, useEvents, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useCountdown } from 'shared/hooks'
import styles from './styles.module.css'

export interface ISelectGiftcardProps {
  handleToggleGiftCardModal: () => void
}

export const SelectGiftcard = ({
  handleToggleGiftCardModal
}: ISelectGiftcardProps) => {
  const { innerWidth } = useMobile()
  const [isShowBlockedAlert, setIsShowBlockedAlert] = useState(true)
  const { formattedTime, startCountdown, isActive, setTime } = useCountdown({
    minutes: 0
  })

  const isMobile = innerWidth < 1280

  useEvents({
    eventType: 'toggleGiftcardBlocked',
    callBack: ({ detail: { show } }) => {
      setIsShowBlockedAlert(show)
      if (show) {
        const savedSeconds = localStorage.getItem('countdownSeconds')
        if (savedSeconds) {
          setTime({ seconds: parseInt(savedSeconds, 10) })
        }
        startCountdown()
      }
    }
  })

  useEffect(() => {
    setIsShowBlockedAlert(isActive)
    trigger({ eventType: 'toggleGiftcardBlocked', data: { show: isActive } })
  }, [isActive])

  return (
    <Column id={getBemId('giftcard', 'container')}>
      <Row
        alignItems='center'
        borderRadius={getGlobalStyle('--border-radius-md')}
        clickable='pointer'
        customClassName={classNames({
          [styles.giftcard]: isShowBlockedAlert
        })}
        id={getBemId('select-giftcard', 'container')}
        justifyContent='between'
        maxHeight={isMobile ? 'max-content' : '64px'}
        padding='16px'
      >
        <Row
          alignItems='center'
          clickable='pointer'
          gap={12}
        >
          <Picture
            hasURL
            height={isMobile ? '29px' : '42px'}
            src='/images/giftcard.png'
            width={isMobile ? '29px' : '42px'}
          />
          <Text
            clickable='pointer'
            fontSize={isMobile ? 'md' : 'base'}
            fontWeight='medium'
            lineHeight='17px'
          >
            Giftcard
          </Text>
        </Row>
        <Row
          clickable='pointer'
          maxWidth='max-content'
        >
          <Text
            clickable='pointer'
            customColor={getGlobalStyle('--color-primary-light-red')}
            fontSize={isMobile ? 'md' : 'base'}
            fontWeight='medium'
            id={getBemId('select-giftcard', 'add-button')}
            lineHeight='17px'
            onClick={handleToggleGiftCardModal}
          >
            Agregar
          </Text>
        </Row>
      </Row>
      {isShowBlockedAlert && (
        <>
          <Spacer.Horizontal size={4} />
          <Row
            id={getBemId('giftcard-blocked', 'container')}
            padding='0px 16px'
          >
            <Row
              alignItems='center'
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius='8px'
              customWidth='max-content'
              gap={12}
              id={getBemId('giftcard-blocked', 'card')}
              justifyContent='center'
              padding='12px 16px'
            >
              <Icon
                color={getGlobalStyle('--color-feedback-info')}
                customSize={24}
                name='InfoFillThin'
              />
              <Text
                color='black'
                display='ruby'
                fontSize='md'
              >
                Por tu seguridad, la opción de pago con giftcard estará
                disponible en &nbsp;
                <Text
                  fontWeight='semibold'
                  type='span'
                  width='max-content'
                >
                  {formattedTime}
                </Text>
              </Text>
            </Row>
          </Row>
          <Spacer.Horizontal size={16} />
        </>
      )}
    </Column>
  )
}
