import { useEffect, useRef, useState } from 'react'
import {
  Backbone,
  Column,
  CustomPortal,
  NotificationBubble,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  useCards,
  useCheckoutV2,
  useEvents,
  isValidArrayWithData,
  useSession,
  trigger,
  replaceStrings
} from '@smu-chile/pkg-unimarc-hooks'
import {
  PaymentMethods,
  Discounts,
  Summary,
  CarouselProducts,
  ToastWrapper,
  SaveBags,
  CheckoutMainLoader,
  PayBtn,
  ModalErrorDeliveryWindow,
  TransitionPayBtn,
  ErrorPageV2
} from './components'
import { TPortalProps } from './components/ToastWrapper/helpers/interfaces'
import {
  CHECKOUT_WITHOUT_PRODUCTS,
  KIND_OF_GIFTCARD,
  LOCALSTORAGE_KEYS
} from 'shared/constants'
import { useCheckoutConfig, useSetLastCard } from 'shared/hooks'
import { useDataLayers } from 'shared/hooks/useDataLayers'
import styles from './CheckoutMain.module.css'

export const CheckoutMain = () => {
  const [isSelectCheck, setIsSelectCheck] = useState(false)
  const [toastProps, setToastProps] = useState({
    portalRootId: '',
    type: '',
    show: false,
    codeError: '',
    success: false,
    toastMessage: ''
  })
  const toastRef = useRef(null)
  const sendDataLayerError = useRef(false)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [showExtraSpacePayBtn, setShowExtraSpacePayBtn] = useState(false)
  const [showNotificationBubble, setShowNotificationBubble] = useState(false)

  const { isLoading: isLoadingSession, isLoggedIn } = useSession()
  const { mutateCheckoutConfig, checkoutConfig } = useCheckoutConfig()
  const { isLoading: isLoadingCheckout, data: dataCheckout } = useCheckoutV2()
  const { isLoading: isLoadingCards, data: dataCards } = useCards({
    version: 2
  })
  const { handleSetLastCard } = useSetLastCard()
  const { pushEvent, getErrorEvent } = useDataLayers()

  const portalProps = toastProps?.portalRootId
    ? ({
        position: 'relative',
        positionContainer: 'relative',
        right: 'initial',
        top: 'initial',
        width: '100%',
        maxWidth: '100%',
        padding: '0px 12px 0px 8px'
      } as TPortalProps)
    : undefined
  const isLoading = isLoadingCheckout || isLoadingCards

  useEvents({
    eventType: 'checkoutToast',
    callBack: ({
      detail: { show, success, codeError, toastMessage, type, portalRootId }
    }) => {
      setToastProps((prevState) => {
        return {
          ...prevState,
          portalRootId,
          type,
          show,
          codeError,
          success,
          toastMessage
        }
      })
    }
  })

  useEvents({
    eventType: 'toggleToastGiftCardNominative',
    callBack: ({ detail: { show } }) => {
      handleClickOnBubble(show)
    }
  })

  useEvents({
    eventType: 'isLoadingPaymentButton',
    callBack: ({ detail: { isLoading } }) => {
      setIsLoadingButton(isLoading)
    }
  })

  const handleClickOnBubble = (show: string) => {
    setShowNotificationBubble(show === 'true')
    localStorage.setItem(LOCALSTORAGE_KEYS.SHOW_GC_NOMINATIVE_TOAST, show)
  }

  const handleToastVisibility = () => {
    setToastProps((prevState) => {
      return {
        ...prevState,
        show: false,
        codeError: '',
        success: false
      }
    })
  }

  const handleRedirectToCart = () => {
    window.location.href = process.env.NEXT_PUBLIC_CARTURL
  }

  const handleRedirectToHome = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_HOMEURL}/?login=true`
  }

  const handleCheck = (value: boolean) => {
    setIsSelectCheck(value)
  }

  useEffect(() => {
    const isNullNominativeToast =
      localStorage.getItem(LOCALSTORAGE_KEYS.SHOW_GC_NOMINATIVE_TOAST) === null

    if (isNullNominativeToast) {
      localStorage.setItem(LOCALSTORAGE_KEYS.SHOW_GC_NOMINATIVE_TOAST, 'true')
    }

    setTimeout(() => {
      localStorage.setItem(LOCALSTORAGE_KEYS.SHOW_GC_NOMINATIVE_TOAST, 'false')
    }, 5000)
  }, [])

  useEffect(() => {
    let hasEnoughAmount = false
    let isCurrentGiftCardNominative = false

    if (dataCards?.nominatives) {
      const currentGiftCardActive = dataCheckout?.paymentInfo?.giftCards?.find(
        (giftCard) => {
          return giftCard.isActive
        }
      )
      isCurrentGiftCardNominative =
        currentGiftCardActive?.giftcardType?.toUpperCase() ===
        KIND_OF_GIFTCARD.NOMINATIVA
      const getMaxBalance = Math.max(
        ...(dataCards?.nominatives?.map((card) => {
          return replaceStrings(card.balance)
        }) || [])
      )
      const orderGiftCards = Object.assign([], dataCards?.nominatives).reverse()
      const findGiftCard = orderGiftCards?.find((card) => {
        return replaceStrings(card.balance) === getMaxBalance
      })
      hasEnoughAmount =
        replaceStrings(findGiftCard?.balance) >=
        replaceStrings(dataCheckout?.value)
    }

    const validateToShowNotification =
      isValidArrayWithData(dataCards?.nominatives) &&
      localStorage.getItem(LOCALSTORAGE_KEYS.SHOW_GC_NOMINATIVE_TOAST) ===
        'true' &&
      hasEnoughAmount &&
      !isCurrentGiftCardNominative

    setShowNotificationBubble(validateToShowNotification)
  }, [dataCards, dataCheckout])

  useEffect(() => {
    // read the query params to assign the last cart as the selected card
    const initialValidaitons = async () => {
      if (!isLoadingCheckout && !isLoadingCards) {
        setLoadingPayment(true)
        await handleSetLastCard()
        setLoadingPayment(false)
      }
    }

    initialValidaitons()
  }, [isLoadingCheckout])

  useEffect(() => {
    handleCheck(checkoutConfig?.termsAndConditions)
  }, [checkoutConfig])

  useEffect(() => {
    mutateCheckoutConfig({ isLoading })
    // redirect to home 'cause client should login again
    if (dataCheckout?.status === 401) handleRedirectToHome()
  }, [isLoading])

  useEffect(() => {
    // redirect to home 'cause client should login again
    if (!isLoading && !isLoadingSession && !isLoggedIn) handleRedirectToHome()
  }, [isLoadingSession, isLoggedIn, isLoading])

  useEffect(() => {
    if (isValidArrayWithData(dataCheckout?.exceptions)) {
      const isCartEmpty = dataCheckout?.exceptions?.find((exception) => {
        return exception?.code === CHECKOUT_WITHOUT_PRODUCTS
      })
      if (isCartEmpty) handleRedirectToCart()
    }
  }, [dataCheckout?.exceptions])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setShowExtraSpacePayBtn(!entry.isIntersecting)
        })
      },
      { threshold: 1 }
    )

    const getBtnElementById = document.getElementById(
      getBemId('checkout', 'bannerContainer_banner')
    )

    if (getBtnElementById) {
      observer.observe(getBtnElementById)
    }

    return () => {
      return observer.disconnect()
    }
  }, [isLoading, loadingPayment, typeof window])

  if (
    dataCheckout?.error ||
    (dataCards?.error && !dataCards?.error?.includes('not found'))
  ) {
    if (!sendDataLayerError.current) {
      if (dataCards?.error) {
        pushEvent({ endpoint: 'cards', error: dataCards?.error })
      }
      if (dataCheckout?.error) {
        pushEvent({ endpoint: 'checkout', error: dataCheckout?.error })
      }
      sendDataLayerError.current = true
    }
    const errorCode = getErrorEvent({
      endpoint: dataCheckout?.error ? 'checkout' : 'cards',
      error: dataCheckout?.error ? dataCheckout?.error : dataCards?.error
    })
    const show_code =
      errorCode?.show_code ||
      dataCheckout?.code ||
      dataCards?.code ||
      dataCheckout?.status?.toString()

    return <ErrorPageV2 code={show_code as string} />
  }

  if (isLoadingButton) {
    return <TransitionPayBtn />
  }
  if (isLoading || loadingPayment || typeof window === 'undefined') {
    return <CheckoutMainLoader />
  }

  return (
    <>
      <ModalErrorDeliveryWindow />
      <BigScreen>
        <Row>
          <Column
            gap={24}
            margin='0 0 100px 0'
            maxWidth='700px'
          >
            <PaymentMethods showNotificationBubble={showNotificationBubble} />
            <Discounts />
            {checkoutConfig?.showSaveBags && <SaveBags />}
            <CarouselProducts />
          </Column>
          <Spacer.Vertical customSize={44} />
          <Column
            gap={20}
            margin='0 0 100px 0'
            maxWidth='375px'
            position='sticky'
          >
            {showExtraSpacePayBtn && <Spacer.Horizontal customSize={12} />}
            {toastProps?.show && (
              <CustomPortal rootId={toastProps?.portalRootId}>
                <ToastWrapper
                  codeError={toastProps.codeError}
                  handleToastVisibility={handleToastVisibility}
                  portalProps={portalProps}
                  ref={toastRef}
                  success={toastProps.success}
                  toastMessage={toastProps.toastMessage}
                  type={toastProps.type}
                />
              </CustomPortal>
            )}
            <PayBtn
              isLoadingButton={isLoadingButton}
              setIsLoadingButton={setIsLoadingButton}
            />
            <Summary
              handleCheck={handleCheck}
              isSelectCheck={isSelectCheck}
            />
            {isLoading ? (
              <Row justifyContent='center'>
                <Row maxWidth='109px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-lg')}
                    height={14}
                  />
                </Row>
              </Row>
            ) : (
              <Row
                clickable='pointer'
                justifyContent='center'
                onClick={handleRedirectToCart}
              >
                <Text
                  clickable='pointer'
                  customClassName={styles.goBack__button}
                  fontSize='lg'
                  fontWeight='medium'
                  id={getBemId('checkout-main', 'back-to-cart')}
                >
                  Volver al carro
                </Text>
              </Row>
            )}
          </Column>
        </Row>
      </BigScreen>
      <SmallScreen>
        <Column
          alignItems='center'
          gap={24}
        >
          <Column
            gap={24}
            padding='0 16px'
          >
            {toastProps?.show && (
              <CustomPortal rootId={toastProps?.portalRootId}>
                <ToastWrapper
                  codeError={toastProps.codeError}
                  handleToastVisibility={handleToastVisibility}
                  portalProps={portalProps}
                  ref={toastRef}
                  success={toastProps.success}
                  toastMessage={toastProps.toastMessage}
                  type={toastProps.type}
                />
              </CustomPortal>
            )}
            <PaymentMethods showNotificationBubble={showNotificationBubble} />
            <Discounts />
            {checkoutConfig?.showSaveBags && <SaveBags />}
            <CarouselProducts />
          </Column>
          <Column
            gap={24}
            padding='0 16px'
          >
            <Summary
              handleCheck={handleCheck}
              isSelectCheck={isSelectCheck}
            />
          </Column>
          <Spacer.Horizontal customSize={16} />
          <Row
            bottom='0'
            position='sticky'
            zIndex='20'
          >
            {showNotificationBubble && (
              <NotificationBubble
                customTop='-46px'
                isSticky={true}
                label='💡 ¡Ya puedes usar tu Giftcard colaborador!'
                onClick={() => {
                  trigger({
                    eventType: 'toggleToastGiftCardNominative',
                    data: { show: 'false' }
                  })
                  trigger({ eventType: 'toggleModalGifcard' })
                }}
              />
            )}
            <Column
              backgroundColor={getGlobalStyle('--color-base-white')}
              borderRadius={getGlobalStyle('--border-radius-md')}
              boxShadow={getGlobalStyle('--box-shadow-xs')}
              padding='20px 16px 16px 16px'
            >
              <PayBtn
                isLoadingButton={isLoadingButton}
                setIsLoadingButton={setIsLoadingButton}
              />
            </Column>
          </Row>
        </Column>
      </SmallScreen>
    </>
  )
}
