import { useState, useEffect, useCallback } from 'react'

interface CountdownInput {
  minutes?: number
  seconds?: number
}

/**
 * Custom React hook to implement a countdown timer.
 * @param initialTime - An object with `minutes` and `seconds` to set the initial countdown value.
 * @returns An object containing:
 *   - `seconds`: The current countdown value in total seconds.
 *   - `formattedTime`: The countdown in "MM:SS" format.
 *   - `setTime`: Function to set the countdown value using an object with `minutes` and `seconds`.
 *   - `startCountdown`: Function to start the countdown.
 *   - `resetCountdown`: Function to reset the countdown to the initial value.
 */
export const useCountdown = (initialTime: CountdownInput) => {
  const initialTotalSeconds =
    (initialTime.minutes || 0) * 60 + (initialTime.seconds || 0)

  const [seconds, setSeconds] = useState(initialTotalSeconds)
  const [isActive, setIsActive] = useState(false)

  const startCountdown = useCallback(() => {
    setIsActive(true)
  }, [])

  const resetCountdown = useCallback(() => {
    setIsActive(false)
    setSeconds(initialTotalSeconds)
  }, [initialTotalSeconds])

  const setTime = useCallback(
    ({ minutes = 0, seconds = 0 }: CountdownInput) => {
      setIsActive(false)
      setSeconds(minutes * 60 + seconds)
    },
    []
  )

  const formattedTime = useCallback(() => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`
  }, [seconds])

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined
    const savedSeconds = localStorage.getItem('countdownSeconds')
    const currentTimer = seconds || parseInt(savedSeconds || '0')

    if (isActive && currentTimer > 1) {
      timer = setTimeout(() => {
        setSeconds((prev) => {
          return (prev || currentTimer) - 1
        })
        localStorage.setItem('countdownSeconds', currentTimer.toString())
      }, 1000)
    } else if (currentTimer <= 1) {
      setIsActive(false)
      setSeconds(0)
      localStorage.removeItem('countdownSeconds')
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isActive, seconds])

  useEffect(() => {
    const savedSeconds = localStorage.getItem('countdownSeconds')
    if (savedSeconds) {
      setSeconds(Number(savedSeconds))
      setIsActive(true)
    }
  }, [])

  return {
    seconds,
    formattedTime: formattedTime(),
    isActive,
    setTime,
    startCountdown,
    resetCountdown
  }
}
